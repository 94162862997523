/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-dupe-class-members */
/* eslint-disable no-unreachable */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
/* eslint-disable semi */
import { amgApi } from "@/service/axios";

// General services on module MetaMedia after refactoring
class CompaniesService {
  async getCompanies(body, page) {
    try {
      const data = await amgApi.post(
        `management/companies/get-companies?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getCompanies:", error);
      throw error;
    }
  }

  async deleteCompanies(body) {
    try {
      const data = await amgApi.post(
        "management/companies/delete-company",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteCompanies:", error);
      throw error;
    }
  }

  async saveCompanies(body) {
    try {
      const data = await amgApi.post("management/companies/save-company", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on saveCompanies:", error);
      throw error;
    }
  }

  async getStatesEeuu(params) {
    try {
      const data = await amgApi.get("/commons/get-eeuu-states", { params });
      return data;
    } catch (error) {
      console.log("Something went wrong on getStatesEeuu:", error);
      throw error;
    }
  }

  async getCompanyById(body) {
    try {
      const data = await amgApi.post("management/companies/get-company", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on getCompanyById:", error);
      throw error;
    }
  }

  async getCompanyCountryById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/get-company-country",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getCompanyById:", error);
      throw error;
    }
  }

  async updateCompany(body) {
    try {
      const data = await amgApi.post("management/companies/edit-company", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on updateCompany:", error);
      throw error;
    }
  }

  async deleteMemberOfCompany(body) {
    try {
      const data = await amgApi.post(
        "management/companies/delete-member-from-company",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteMemberOfCompany:", error);
      throw error;
    }
  }

  async getDashboardData(body) {
    try {
      const data = await amgApi.post(
        "management/companies/dashboard/get-dashboard-data",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getDashboardData:", error);
      throw error;
    }
  }

  async getBankAccountsAll(body) {
    try {
      const data = await amgApi.post(
        "management/companies/dashboard/get-man-bank-accounts-all",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getBankAccountsAll:", error);
      throw error;
    }
  }

  async getBankAccountById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/dashboard/get-man-bank-account-by-id",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getBankAccountsAll:", error);
      throw error;
    }
  }

  async searchBankAccountAll(body, page) {
    try {
      const data = await amgApi.post(
        `management/companies/bank-account/man-search-bank-account?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on searchBankAccountAll:", error);
      throw error;
    }
  }

  async searchScheduleTransfer(body, page) {
    try {
      const data = await amgApi.post(
        `management/companies/transfer-schedule/get-all-schedule-transfer?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on searchBankAccountAll:", error);
      throw error;
    }
  }

  async deleteBankAccounts(body) {
    try {
      const data = await amgApi.post(
        "management/companies/bank-account/man-bank-account-delete",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteBankAccounts:", error);
      throw error;
    }
  }

  async deleteTransferSchedule(body) {
    try {
      const data = await amgApi.post(
        "management/companies/transfer-schedule/transfer-schedule-delete",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteBankAccounts:", error);
      throw error;
    }
  }

  async getManagementUsers(body) {
    try {
      const data = await amgApi.get(
        "management/companies/transfer-schedule/get-management-users",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteBankAccounts:", error);
      throw error;
    }
  }

  async confirmScheduleTransfer(body) {
    try {
      const data = await amgApi.post(
        "management/companies/transfer-schedule/transfer-schedule-complete",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteBankAccounts:", error);
      throw error;
    }
  }

  async saveTransferSchedule(body) {
    try {
      const data = await amgApi.post(
        "management/companies/transfer-schedule/transfer-schedule-save",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on saveTransferSchedule:", error);
      throw error;
    }
  }

  async getBankAccountByCurrency(body) {
    try {
      const data = await amgApi.post(
        "management/companies/transfer-schedule/bank-account-by-currency",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getBankAccountByCurrency:", error);
      throw error;
    }
  }

  async getCouterScheduleTransfer(body) {
    try {
      const data = await amgApi.post(
        "management/companies/transfer-schedule/transfer-schedule-get-counter",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getCouterScheduleTransfer:", error);
      throw error;
    }
  }

  async getTransferShowById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/transfer-schedule/transfer-schedule-show",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getTransferShowById:", error);
      throw error;
    }
  }

  async saveBankAccounts(body) {
    try {
      const data = await amgApi.post(
        "management/companies/bank-account/save-bank-account-man",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on saveBankAccounts:", error);
      throw error;
    }
  }

  async updateBankAccounts(body) {
    try {
      const data = await amgApi.post(
        "management/companies/bank-account/update-bank-account-man",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on updateBankAccounts:", error);
      throw error;
    }
  }

  async getBankAccountCounter(body) {
    try {
      const data = await amgApi.post(
        "management/companies/bank-account/get-man-bank-accounts-counter",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getBankAccountCounter:", error);
      throw error;
    }
  }

  async getBankAccountById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/bank-account/get-man-bank-account-id",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getBankAccountById:", error);
      throw error;
    }
  }

  async deleteIncomeById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/delete-income",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteIncomeById:", error);
      throw error;
    }
  }

  async getCustomerById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/get-customer",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getCustomerById:", error);
      throw error;
    }
  }

  async getParentsAccount(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/man-get-parent-accounts",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getParentsAccount:", error);
      throw error;
    }
  }

  async getAllVendors(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/get-man-vendors-all",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllVendors:", error);
      throw error;
    }
  }

  async deleteProductsAndServicesById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/delete-service",
        body
      );
      return data;
    } catch (error) {
      console.log(
        "Something went wrong on deleteProductsAndServicesById:",
        error
      );
      throw error;
    }
  }

  async deleteCustomersById(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/delete-customer",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteCustomersById:", error);
      throw error;
    }
  }

  async saveCustomer(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/save-man-customer",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on saveCustomer:", error);
      throw error;
    }
  }

  async saveService(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/save-service",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on saveService:", error);
      throw error;
    }
  }

  async getServiceAndProductbyId(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/man-product-services-get-service",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getServiceAndProductbyId:", error);
      throw error;
    }
  }

  async getManProductServiceCategory(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/man-product-services-get",
        body
      );
      return data;
    } catch (error) {
      console.log(
        "Something went wrong on getManProductServiceCategory:",
        error
      );
      throw error;
    }
  }

  async saveCategory(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/man-product-services-save",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on saveCategory:", error);
      throw error;
    }
  }

  async getAllIncomes(body, page) {
    try {
      const data = await amgApi.post(
        `management/companies/incomes/get-man-incomes?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getBankAccountById:", error);
      throw error;
    }
  }

  async getAllBankAccountIncomes(body, page) {
    try {
      const data = await amgApi.post(
        `management/companies/incomes/bank-transfer-get-income?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllBankAccountIncomes:", error);
      throw error;
    }
  }

  async getAllManProductsServices(body, page) {
    try {
      const data = await amgApi.post(
        `management/companies/incomes/man-product-services-get-all?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllManProductsServices:", error);
      throw error;
    }
  }

  async getProductsServicesForInvoices(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/get-product-services-invoice",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getIncome(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/get-income",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Categories bri start
  async getCategoriesByCompany(body) {
    try {
      const data = await amgApi.post(
        "/management/categories/man-get-categories-by-company",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getCategoryReport(body) {
    try {
      const data = await amgApi.post(
        "/management/categories/man-category/report",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCategoryReportPdf(body) {
    try {
      const data = await amgApi.post(
        "/management/categories/category-report-pdf",
        body
      );
      return data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTransactions(body) {
    try {
      const data = await amgApi.post(
        "/management/payments/c-p/transactions",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async exportExcel(body) {
    try {
      const data = await amgApi.post(
        "/management/payments/export/transactions",
        body,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Categories bri end
  // Expenses bri start
  async getManTransactions(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/get-man-transactions",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteExpenseTransaction(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/delete-transaction",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteBankTransfer(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/delete-bank-transfer",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPaymentMethods(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/get-payment-methods",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Vendors bri inside expenses
  async getVendors(body) {
    try {
      const data = await amgApi.post("/management/man-search-vendors", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteVendor(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/vendors/delete-vendor",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async activateVendor(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/vendors/man-vendor-activate",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deactivateVendor(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/vendors/man-vendor-deactivate",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveVendor(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/vendors/save-man-vendor",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getVendor(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/vendors/get-vendor",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Bank transfers bri
  async getBankTransfers(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/get-bank-transfer",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTransactionExpense(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/get-transaction",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllManBankAccounts(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/dashboard/get-man-bank-accounts-all",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async savePaymentMethod(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/save-payment-methods",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveTransactionExpense(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/save-transaction-category-id",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteTransactionDetail(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/delete-transaction-detail",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteTransactionFile(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/delete-transaction-file",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTerms(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/get-bill-term",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveTerm(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/expenses/save-bill-term",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getOtherCompanies(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/get-man-companies-search-select",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveInvoice(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/incomes/save-invoice",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteIncomeFile(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/incomes/delete-income-file",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteIncomeDetail(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/incomes/delete-income-detail",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFavorites(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/transfer-schedule/get-favorites",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveFavorite(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/transfer-schedule/add-to-favorites",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFavorite(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/transfer-schedule/delete-favorite",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  // Expenses bri end

  async getAllManCustomers(body, page) {
    try {
      const data = await amgApi.post(
        `management/companies/incomes/man-search-customer?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllManCustomers:", error);
      throw error;
    }
  }

  async getCustomers(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/get-man-customers-all",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Profit and loss
  async getProfitLossReport(body) {
    try {
      const data = await amgApi.post(
        "management/companies/profit-loss/report-profit-loss",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async exportExcelReportProfitLoss(body) {
    try {
      const data = await amgApi.post("/management/payments/report/p-l", body, {
        responseType: "blob",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async exporPdfReportProfitLoss(body) {
    try {
      const data = await amgApi.post(
        "/management/companies/profit-loss/export-pl-pdf",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // Files
  async getFilesByCompany(body) {
    try {
      const data = await amgApi.post(
        "management/companies/files/search-files-company",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFileCompany(body) {
    try {
      const data = await amgApi.post(
        "management/companies/files/update-file-company",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFileCompany(body) {
    try {
      const data = await amgApi.post(
        "management/companies/files/delete-file-company",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFilesVendor(body) {
    try {
      const data = await amgApi.post(
        "management/companies/expenses/vendors/delete-man-vendor",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFilesCustomers(body) {
    try {
      const data = await amgApi.post(
        "management/companies/incomes/delete-man-customer",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveFolderCompany(body) {
    try {
      const data = await amgApi.post(
        "management/companies/files/save-folder-company",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveFileCompany(body) {
    try {
      const data = await amgApi.post(
        "management/companies/files/save-file-company",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async exportPdfCheck(body) {
    try {
      const data = await amgApi.post(
        "management/companies/expenses/export-pdf-check",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getListCompanies(body) {
    try {
      // const data = await amgApi.post('management/companies/get-list-companies', body)
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCompanyTypes(body) {
    try {
      const data = await amgApi.post(
        "management/companies/company-type/get-company-types",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getBanks(body) {
    try {
      const data = await amgApi.get("management/bank/get-banks", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async verifyCeoPassword(body) {
    try {
      const data = await amgApi.post(
        "management/companies/verify-ceo-password",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveManBank(body) {
    try {
      const data = await amgApi.post("management/companies/save-bank", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getTaxRegimes(body) {
    try {
      const data = await amgApi.get(
        "management/companies/tax-regime/get-tax-regimes");
      return data;
    } catch (error) {
      throw error;
    }
  }
  async createCompanyType(body) {
    try {
      const data = await amgApi.post("management/companies/company-type/create-company-type", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deletedCia(body) {
    try {
      const data = await amgApi.post("management/companies/deleted-cia", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new CompaniesService();
